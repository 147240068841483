<template>
  <footer class="py-3 footer text-center">
    <div class="container text-center">
      <div class="row align-items-center">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div class="text-sm text-center copyright text-muted center"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'">ACE Laboratories
            ©
            {{ new Date().getFullYear() }}, All Rights Reserved.<br />made with
            <i class="fa fa-heart"></i> by
            <a href="https://acelabtech.com" class="font-weight-bold" target="_blank">acelabtech.com</a>
          </div>
        </div>

      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
