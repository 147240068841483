


<template>
    <div class="row p-4">
        <h1 class="p-4" :style= "[getRoute() === 'contact-us' ? {color : 'white', }: null ]"  style="text-align: left;">Contact Us</h1>
        <div class="col-lg-8">
            <div class="row mt-4">
                <div class="col-xl-6 mb-xl-2 mb-4">
                    <div class="card">
                        <div class="card-header pb-0 px-3">
                            <h6 class="mb-0" style="text-align: left;">Call Us</h6>
                        </div>
                        <div class="card-body pt-2 p-3">
                            <ul class="list-group">
                                <li class="list-group-item border-0 d-flex p-2 mb-2 bg-gray-100 border-radius-lg">
                                    <h6 class="text-sm" style="text-align: left;"><a href="tel:+919327770619">+91
                                            93277 70619</a>
                                    </h6>
                                </li>
                                <li class="list-group-item border-0 d-flex p-2 mb-2 bg-gray-100 border-radius-lg">
                                    <h6 class="text-sm" style="text-align: left;">
                                        <a href="tel:+919724325992">+91
                                            97243 25992</a>

                                    </h6>
                                </li>
                                <!-- <li class="list-group-item border-0 d-flex p-2 mb-2 bg-gray-100 border-radius-lg">
                                    <h6 class="text-sm" style="text-align: left;"><a href="tel:+919825168480">+91
                                            98251 68480</a></h6>
                                </li> -->

                            </ul>
                        </div>
                    </div>

                </div>
                <div class="col-xl-6 mb-xl-2 mb-4">
                    <div class="card shadow-none">
                        <div class="card-header pb-0 px-3">
                            <h6 class="mb-0" style="text-align: left;">Email Us</h6>
                        </div>
                        <div class="card-body pt-2 p-3">
                            <ul class="list-group" style="text-align: left;">
                                <li class="list-group-item border-0 d-flex p-2 mb-2 bg-gray-100 border-radius-lg">
                                    <h6 class="text-sm"> <a
                                            href="mailto:info.acelab.har@gmail.com?subject = Feedback&body = Message"
                                            target="_blank">info.acelab.har@gmail.com</a>

                                    </h6>
                                </li>
                                <li class="list-group-item border-0 d-flex p-2 mb-2 bg-gray-100 border-radius-lg">
                                    <h6 class="text-sm"> <a
                                            href="mailto:info.acelab.gfp@gmail.com?subject = Feedback&body = Message"
                                            target="_blank">info.acelab.gfp@gmail.com</a>

                                    </h6>
                                </li>
                                <!-- <li class="list-group-item border-0 d-flex p-2 mb-2 bg-gray-100 border-radius-lg">
                                    <h6 class="text-sm"> <a
                                            href="mailto:info.acelab.kp@gmail.com?subject = Feedback&body = Message"
                                            target="_blank">info.acelab.kp@gmail.com</a></h6>
                                </li> -->

                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 mb-4 ">
                    <div class="card shadow-none">
                        <div class="card-header pb-0 px-3" style="text-align: left;">
                            <h6 class="mb-0">Address</h6>
                        </div>
                        <div class="card-body pt-2 p-3">
                            <ul class="list-group" style="text-align: left;">
                                <li class="list-group-item border-0 d-flex p-2 mb-2 bg-gray-100 border-radius-lg">
                                    <h6 class="text-sm">Plot no. 307+308 to 311/26,
                                        Old Rajat Pharma, Nr. Ramdev Chowkdi, GIDC, Ankleshwar- 393002, Dist.-
                                        Bharuch, Gujarat, India. </h6>
                                    <p class="p-4"></p>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="card mt-4 shadow-none">
                <div class="p-2 pb-0" height="400">
                    <iframe height="388" width="100%" style="border-radius: 10px; overflow: hidden;" loading="lazy"
                        allowfullscreen referrerpolicy="no-referrer-when-downgrade"
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCfNLP3Hsj0yyMnSum495p0hc0hmN5ajP4&q=Ace+Lab+Ankleshwar">
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactUs",
     props: {
        textColor: Boolean
    },
     methods: {
        getRoute() {
            const routeArr = this.$route.path.split("/");
            return routeArr[1];
        }
    }
}
</script>

<style scoped>
</style>
