<template>
  <div style="text-align: justify">
    <h1
      class="p-4"
      :style="[getRoute() === 'about-us' ? { color: 'white' } : null]"
      style="text-align: justify"
    >
      About Us
    </h1>
    <div class="row p-4">
      <div class="p-4 card shadow-none" style="text-align: justify">
        ACE Laboratories is equipped with sophisticated instruments with
        qualified staff. Services providing by us, Bulk drug and Pharmaceutical
        testing, Chemical testing, Microbiological testing, Phyto-chemical and
        Agro-chemical testing, Dye and Pigment testing, Water testing, Food and
        Dairy product testing as well as DMF and Dosier preparation.
        <p class="p-2"></p>
        <span style="text-align: justify"
          >Ace Labs is
          <b>NABL ACCREDITED (ISO/IEC 17025:2017, CERT. NO. TC-11941)</b> &
          <b>FDCA, GUJARAT APPROVED (LIC.NO. GTL/37/68)</b> &
          <b>ISO 9001:2015 CERTIFIED</b>. Further, we also apply for
          <b>FSSAI</b> and <b>AYUSH</b> approval.</span
        >
      </div>
      <p class="p-4"></p>
      <div class="row">
        <div class="col-lg-4 mb-lg-0 mb-4 p-2">
          <h4 class="p-1" style="text-align: justify">Quality Policy</h4>
          <div class="card shadow-none center align-items-stretch">
            <div class="card-body">
              <span class="card-text" style="text-align: justify"
                >To provide excellent service to determine quality of material.
                <p class="p-1"></p>
                <p></p>
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-lg-0 mb-4 p-2">
          <h4 class="p-1 align-items-stretch" style="text-align: justify">
            The Mission
          </h4>
          <div class="card shadow-none center align-items-stretch">
            <div class="card-body">
              <span class="card-text" style="text-align: justify"
                >To provide quality, excellence and accuracy to our client for
                improve level of standards.</span
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-lg-0 mb-4 p-2">
          <h4 class="p-1" style="text-align: justify">The Vision</h4>
          <div class="card shadow-none center align-items-stretch">
            <div class="card-body">
              <span class="card-text" style="text-align: justify"
                >To be an authenticate and traceable analytical testing service
                sector for our client.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>

<style scoped></style>
