<template>
  <div class="py-0 footer text-center">
    <marquee class="news-content" scrollamount="13">
      <div class="inner">
        <p>NABL ACCREDITED (ISO/IEC 17025:2017, CERT. NO. TC-11941)</p>
        <p>FDCA, GUJARAT APPROVED (LIC.NO. GTL/37/68)</p>
        <p>ISO 9001:2015 CERTIFIED</p>
        <p>PHARMA SOFTWARE/APPLICATIONS</p>
        <p>
          Analytical testing facility, MLT &amp; BET Study, Stability Sample
          Insertion and Analysis. Genotoxic impurities analysis, NDMA and NDEA
          Analysis, Ethylene Oxide and Dioxane analysis, Elemental Analysis, DMF
          and Dossier preparation
        </p>
      </div>
    </marquee>
    <div>
      <div class="p-4">
        <div class="card card-carousel overflow-hidden h-100 p-0">
          <div id="carouseltest" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner border-radius-lg h-100">
              <!-- <div class="carousel-item h-100 " data-interval="10000">
                <img
                  src="../../../assets/img/carousel-1.jpeg"
                  class="d-block w-100"
                  alt="../../../assets/img/carousel-1.jpeg"
                /> -->
              <!-- <div
                  class="carousel-caption d-none d-md-block bottom-0 text-start start-0 ms-5"
                >
                  <div
                    class="icon icon-shape icon-sm bg-white text-center border-radius-md mb-3"
                  >
                    <i class="ni ni-camera-compact text-dark opacity-10"></i>
                  </div>
                  <h5 class="text-white mb-1">Getasdas started with Argon</h5>
                  <p>
                    There’s nothing I really wanted to do in life that I wasn’t
                    able to get good at.
                  </p>
                </div> -->
              <!-- </div> -->
              <div class="carousel-item h-100 active" data-interval="10000">
                <img
                  src="../../../assets/img/carousel-2.jpeg"
                  class="d-block w-100"
                  alt="../../../assets/img/carousel-2.jpeg"
                />
                <!-- <div
                  class="carousel-caption d-none d-md-block bottom-0 text-start start-0 ms-5"
                >
                  <div
                    class="icon icon-shape icon-sm bg-white text-center border-radius-md mb-3"
                  >
                    <i class="ni ni-camera-compact text-dark opacity-10"></i>
                  </div>
                  <h5 class="text-white mb-1">Gest started with Argon</h5>
                  <p>
                    There’s nothing I really wanted to do in life that I wasn’t
                    able to get good at.
                  </p>
                </div> -->
              </div>
            </div>

            <button
              class="carousel-control-prev w-5 me-3"
              type="button"
              role="button"
              data-bs-target="#carouseltest"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next w-5 me-3"
              type="button"
              role="button"
              data-bs-target="#carouseltest"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <about-us-view style="text-align: justify" />
    <services />
    <facilities />
    <licence />
    <contact-us />
  </div>
</template>

<script>
import AboutUsView from "../about-us/AboutUs.vue";
import ContactUs from "../contact-us/ContactUs.vue";
import Facilities from "../facilities/Facilities.vue";
import Services from "../services/Services.vue";
import Licence from "../licence/LicencePage.vue";
import "@jambonn/vue-concise-carousel/dist/vue-concise-carousel.css";
import "../../../assets/css/home.css";
import "../../../assets/js/home.js";
export default {
  name: "HomePage",
  components: {
    AboutUsView,
    ContactUs,
    Facilities,
    Services,
    Licence,
  },
  data() {
    return {
      currentPage: 0,
      isEnableForward: true,
    };
  },
  methods: {
    handlePageChange(currentPage) {
      this.currentPage = currentPage;
      this.isEnableForward = this.$refs.carousel.canAdvanceForward;
    },
    onChangePagination(index) {
      this.$refs.carousel.goToPage(index, "pagination");
    },
    onChangeNavigation(direction) {
      console.log(direction);
      //this.$refs.carousel.handleNavigation(direction);
    },
  },
};
</script>

<style scoped></style>
