<template>
  <div v-show="this.$store.state.layout !== 'landing'" class="card card-plain shadow-none" id="sidenavCard">
    <div class="p-3 card-body text-center w-100 pt-0">


      <h6 class="mb-0 text-dark up">Need Help ?</h6>

      <p class="text-xs font-weight-bold">Please Call US Or Mail @</p>

      <a href="tel:+919327770619" class="mb-2 btn btn-dark btn-sm w-100">+91
        93277 70619</a>
      <a href="tel:+919724325992" class="mb-2 btn btn-dark btn-sm w-100">+91
        97243 25992</a>
      <!-- <a href="tel:+919825168480" class="mb-2 btn btn-dark btn-sm w-100">+91
        98251 68480</a> -->

      <a href="mailto:info.acelab.har@gmail.com?subject = Feedback&body = Message" target="_blank"
        class="mb-2 btn btn-success btn-sm w-100" style="background-color: #732014;">info.acelab.har@gmail.com</a>
      <a href="mailto:info.acelab.gfp@gmail.com?subject = Feedback&body = Message" target="_blank"
        class="mb-2 btn btn-success btn-sm w-100" style="background-color: #732014;">info.acelab.gfp@gmail.com</a>
      <!-- <a href="mailto:info.acelab.kp@gmail.com?subject = Feedback&body = Message" target="_blank"
        class=" btn btn-success btn-sm w-100" style="background-color: #732014;">info.acelab.kp@gmail.com</a> -->

    </div>


  </div>
</template>
<script>
import img from "../../assets/img/illustrations/icon-documentation.svg";

export default {
  name: "sidenav-card",
  props: ["textPrimary", "textSecondary", "iconClass"],
  data() {
    return {
      img
    };
  }
};
</script>