import {
    createApp
} from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import MainInstall from "./main-install";
import VueConciseCarousel from "@jambonn/vue-concise-carousel";
import "@jambonn/vue-concise-carousel/dist/vue-concise-carousel.css";
import bootstrap from "bootstrap";
import "./assets/js/home.js";

const appInstance = createApp(App);

appInstance.component("carousel-v", VueConciseCarousel.Carousel);
appInstance.component("slide-v", VueConciseCarousel.Slide);

appInstance.use("carousel-v", VueConciseCarousel.Carousel);
appInstance.use("slide-v", VueConciseCarousel.Slide);

appInstance.use(store);
appInstance.use(router);
appInstance.use(MainInstall);
appInstance.use(bootstrap);
appInstance.mount("#app");
