<template>
  <div>
    <h1 class="p-4" style="text-align: left; color: white">
      {{ getRoute() }}
    </h1>

    <div
      class="row p-3 col-lg-12 center"
      style="flex-wrap: wrap; justify-content: space-evenly"
    >
      <div
        v-for="(data, index) in getData()"
        :key="index"
        class="card col-lg-3 col-md-4 col-12 d-flex mt-2 mb-4 center shadow-none"
        style="margin-right: 4%; margin-left: 0%; justify-content: space-evenly"
      >
        <div class="card-body col-lg-12 col-md-6 col-12">
          <h6 class="mb-0" style="text-align: left">{{ data.title }}</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesDetails",

  methods: {
    getName() {
      const routeArr = this.$route.path.split("/");
      return routeArr;
    },
    getData() {
      const routeArr = this.$route.params.name;
      if (routeArr === "analytical-services") {
        return {
          0: {
            title: "Pharmaceutical Bulk Durg Analysis",
            init: "P",
            iconBackground: "bg-gradient-primary",
          },
          1: {
            title: "Pharmaceutical Finish Product Analysis",
            init: "P",
            iconBackground: "bg-gradient-primary",
          },
          2: {
            title: "Analytical Method Development and Validation",
            init: "A",
            iconBackground: "bg-gradient-primary",
          },
          3: {
            title: "Microbiological Testing",
            init: "M",
            iconBackground: "bg-gradient-primary",
          },
          4: {
            title: "Chemical Testing",
            init: "C",
            iconBackground: "bg-gradient-primary",
            // title: "Dairy Products Testing",
            // init: "D",
            // iconBackground: "bg-gradient-primary"
          },
          5: {
            title: "Cosmetic Testing",
            init: "A",
            iconBackground: "bg-gradient-primary",
          },
          6: {
            title: "Ayush Products Testing",
            init: "A",
            iconBackground: "bg-gradient-primary",
          },
          7: {
            title: "Food Products Testing",
            init: "D",
            iconBackground: "bg-gradient-primary",
          },
          8: {
            title: "Dye and Pigment Testing",
            init: "C",
            iconBackground: "bg-gradient-primary",
          },
          9: {
            title: "Clean Room Viable Testing",
            init: "C",
            iconBackground: "bg-gradient-primary",
          },

          10: {
            title: "much more",
            init: "C",
          },
        };
      }
      if (routeArr === "regulatory-services") {
        return {
          0: {
            title: "Drug Master File [DMF] Preparation",
            init: "P",
            iconBackground: "bg-gradient-primary",
          },
          1: {
            title: "Open Part And Close Part Preparation",
            init: "P",
            iconBackground: "bg-gradient-primary",
          },
          2: {
            title:
              "Dossier Preparation As Per CTD, ECTD &amp; Other Guidelines",
            init: "A",
            iconBackground: "bg-gradient-primary",
          },
          3: {
            title: "GAP Analysis of QMS System",
            init: "M",
            iconBackground: "bg-gradient-primary",
          },
          4: {
            title: "Regulatory Training",
            init: "C",
            iconBackground: "bg-gradient-primary",
          },
          5: {
            title: "Third-Party External Audit",
            init: "A",
            iconBackground: "bg-gradient-primary",
          },
          6: {
            title: "Technical Package Preparation",
            init: "A",
            iconBackground: "bg-gradient-primary",
          },
        };
      }
      if (routeArr === "stability-study-services") {
        return {
          0: {
            title: "Insertion Of Stability Sample As Per ICH Guideline",
            init: "P",
            iconBackground: "bg-gradient-primary",
          },
          1: {
            title: "Online Stability Sample Analysis",
            init: "P",
            iconBackground: "bg-gradient-primary",
          },
        };
      }
      if (routeArr === "it-services") {
        return {
          0: {
            title: "Product Label with QR code",
          },
          1: {
            title: "APQR AND COA GENERATION",
          },
          2: {
            title: "STABILTY SAMPLE AND TREND DATA MANAGE",
          },
          3: {
            title: "INTERNAL AUDIT RECORD AND CALENDER MANAGE",
          },
          4: {
            title: "TRANING RECORD AND TRAINING CALENDER MANAGE",
          },
          5: {
            title: "CALIBRATION RECORD AND CALENDER MANAGE",
          },
        };
      } else {
        return {};
      }
    },
    getRoute() {
      console.log(this.$route.params.name);
      const routeArr = this.$route.params.name;
      if (routeArr === "analytical-services") {
        return "Analytical Services";
      }
      if (routeArr === "regulatory-services") {
        return "Regulatory Services";
      }
      if (routeArr === "stability-study-services") {
        return "Stability Study Services";
      }
      if (routeArr === "it-services") {
        return "Custom based Application/Software Development";
      }

      return routeArr;
    },
  },
};
</script>
