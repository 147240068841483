import { createRouter, createWebHistory } from "vue-router";

import HomeView from "../components/pages/home/Home.vue";
import AboutUsView from "../components/pages/about-us/AboutUs.vue";
import ContactUs from "../components/pages/contact-us/ContactUs.vue";
import Facilities from "../components/pages/facilities/Facilities.vue";
import Services from "../components/pages/services/Services.vue";
import ServicesDetails from "../components/pages/services/ServicesDetails.vue";

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/home",
      name: "Home",
      component: HomeView,
    },
    {
      path: "/about-us",
      name: "About Us",
      component: AboutUsView,
    },
    {
      path: "/contact-us",
      name: "Contact Us",
      component: ContactUs,
    },
    {
      path: "/facilities",
      name: "Facilities",
      component: Facilities,
    },
    {
      path: "/services",
      name: "Services",
      component: Services,
    },

    {
      path: "/services/:name",
      name: "Services Details",
      component: ServicesDetails,
    },
  ],
});

export default router;
