

<template>
    <div>
        <h1 class="p-4" :style= "[getRoute() === 'facilities' ? { color: 'white', } : null]"  style="text-align: left;">Facilities</h1>
        <div class="row p-3 col-lg-12 center" style="flex-wrap: wrap;justify-content: space-evenly">
            <div v-for="(data, index) in data" :key="index"
                class="card col-lg-3 col-md-4 col-12 d-flex mt-2 mb-4 center shadow-none"
                style="margin-right:4%; margin-left:0%; justify-content: space-evenly">
                <div class="card-body col-lg-12 col-md-6 col-12">

                    <h6 class="mb-0 " style="text-align: left;">{{ data.title }}</h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FacilitiesPage", methods: {
        getRoute() {
            const routeArr = this.$route.path.split("/");
            return routeArr[1];
        }
    }, data() {
        return {
            data: {
                0: {
                    title: "Analytical Section",
                    init: "P",
                    iconBackground: "bg-gradient-primary"
                },
                1: {
                    title: "Stability Area",
                    init: "W",
                    iconBackground: "bg-gradient-primary"
                },
                2: {
                    title: "Microbiology Section",
                    init: "F",
                    iconBackground: "bg-gradient-primary"
                },
                
            },
        };
    },
}
</script>

<style scoped>
</style>
