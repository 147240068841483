<template>
  <h1
    class="p-4"
    :style="[getRoute() === 'services' ? { color: 'white' } : null]"
    style="text-align: left"
  >
    Services
  </h1>

  <div
    class="row p-4 col-lg-12"
    style="flex-wrap: wrap;"
  >
    <div
      v-for="(data, index) in sdata"
      :key="index"
      class="card p-1 col-lg-3 col-md-4 col-12 d-flex mt-2 mb-4 shadow-none"
      style="margin-right: 4%; margin-left: 0%; justify-content: space-evenly"
    >
      <div class="card-body col-lg-12 col-md-6 col-12">
        <h6
          v-if="data.title === 'Analytical Services'"
          class="mb-0"
          style="text-align: left"
        >
          <router-link to="/services/analytical-services"
              >Analytical Services</router-link
            >
        </h6>

        <h6
          v-if="data.title === 'Regulatory Services'"
          class="mb-0"
          style="text-align: left"
        >
           <router-link to="/services/regulatory-services"
              >Regulatory Services</router-link
            >
        </h6>

        <h6
          v-if="data.title === 'Stability Sample Insertion'"
          class="mb-0"
          style="text-align: left"
        >
         <router-link to="/services/stability-study-services"
              >Stability Study Services</router-link
            >
        </h6>

        <h6
          v-if="data.title === 'Custom based Application/Software Development'"
          class="mb-0"
          style="text-align: left"
        >
          <router-link to="/services/it-services"
              >Custom based Application/Software Development</router-link
            >
        </h6>
      </div>
    </div>
  </div>

  <!-- <div>
    <h3
      class="p-4"
      :style="[getRoute() === 'services' ? { color: 'white' } : null]"
      style="text-align: left"
    >
      Analytical Services
    </h3>
    <div
      class="row p-3 col-lg-12 center"
      style="flex-wrap: wrap; justify-content: space-evenly"
    >
      <div
        v-for="(data, index) in data"
        :key="index"
        class="card col-lg-3 col-md-4 col-12 d-flex mt-2 mb-4 center shadow-none"
        style="margin-right: 4%; margin-left: 0%; justify-content: space-evenly"
      >
        <div class="card-body col-lg-12 col-md-6 col-12">
          <h6 class="mb-0" style="text-align: left">{{ data.title }}</h6>
        </div>
      </div>
    </div>
  </div>

  <div>
    <h3 class="p-4" style="text-align: left">Regulatory Services</h3>
    <div
      class="row p-3 col-lg-12 center"
      style="flex-wrap: wrap; justify-content: space-evenly"
    >
      <div
        v-for="(rdata, index) in rdata"
        :key="index"
        class="card col-lg-3 col-md-4 col-12 d-flex mt-2 mb-4 center shadow-none"
        style="margin-right: 4%; margin-left: 0%; justify-content: space-evenly"
      >
        <div class="card-body col-lg-12 col-md-6 col-12">
          <h6 class="mb-0" style="text-align: left">{{ rdata.title }}</h6>
        </div>
      </div>
    </div>
  </div>

  <div>
    <h3 class="p-4" style="text-align: left">Stability Study Services</h3>
    <div
      class="row p-3 col-lg-12 center"
      style="flex-wrap: wrap; justify-content: space-evenly"
    >
      <div
        v-for="(ssdata, index) in ssdata"
        :key="index"
        class="card col-lg-3 col-md-4 col-12 d-flex mt-2 mb-4 center shadow-none"
        style="margin-right: 4%; margin-left: 0%; justify-content: space-evenly"
      >
        <div class="card-body col-lg-12 col-md-6 col-12">
          <h6 class="mb-0" style="text-align: left">{{ ssdata.title }}</h6>
        </div>
      </div>
    </div>
  </div>

  <div>
    <h3 class="p-4" style="text-align: left">IT Services</h3>
    <div class="row p-3 col-lg-12" style="flex-wrap: wrap">
      <div
        v-for="(itdata, index) in itdata"
        :key="index"
        class="card col-lg-3 col-md-4 col-12 d-flex mt-2 mb-4 shadow-none"
        style="margin-right: 4%; margin-left: 0%; justify-content: space-evenly"
      >
        <div class="card-body col-lg-12 col-md-6 col-12">
          <h6 class="mb-0" style="text-align: left">{{ itdata.title }}</h6>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  name: "ServicesPage",
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
  data() {
    return {
      sdata: {
        0: {
          title: "Analytical Services",
        },
        1: {
          title: "Regulatory Services",
        },
        2: {
          title: "Stability Sample Insertion",
        },
        3: {
          title: "Custom based Application/Software Development",
        },
      },
      data: {
        0: {
          title: "Pharmaceutical Bulk Drug + Finish Product Testing",
          init: "P",
          iconBackground: "bg-gradient-primary",
        },
        1: {
          title: "Analytical Method Development And Validation",
          init: "A",
          iconBackground: "bg-gradient-primary",
        },
        2: {
          title: "Microbiological Testing",
          init: "M",
          iconBackground: "bg-gradient-primary",
        },
        3: {
          title: "Chemical Testing",
          init: "C",
          iconBackground: "bg-gradient-primary",
          // title: "Oil Testing",
          // init: "O",
          // iconBackground: "bg-gradient-primary"
        },
        4: {
          title: "Cosmetic Testing",
          init: "C",
          iconBackground: "bg-gradient-primary",
          // title: "Dairy Products Testing",
          // init: "D",
          // iconBackground: "bg-gradient-primary"
        },
        5: {
          title: "Ayush Products Testing",
          init: "A",
          iconBackground: "bg-gradient-primary",
        },
        6: {
          title: "Food Products Testing",
          init: "F",
          iconBackground: "bg-gradient-primary",
        },
        7: {
          title: "Dye + Pigment Testing",
          init: "D",
          iconBackground: "bg-gradient-primary",
        },
        8: {
          title: "Clean room viable Testing",
          init: "C",
          iconBackground: "bg-gradient-primary",
        },
        9: {
          title: "much more",
          init: "C",
        },
      },
      rdata: {
        0: {
          title: "Drug Master File [DMF] Open Part + Close Part Preparation",
          init: "D",
          iconBackground: "bg-gradient-primary",
        },
        1: {
          title: "Dossier Preparation As Per CTD, ECTD & Other Guidelines",
          init: "D",
          iconBackground: "bg-gradient-primary",
        },
        2: {
          title: "GAP Analysis & QMS System",
          init: "G",
          iconBackground: "bg-gradient-primary",
        },
        3: {
          title: "Regulatory Training",
          init: "C",
          iconBackground: "bg-gradient-primary",
        },
        4: {
          title: "Third-Party External Audit",
          init: "C",
          iconBackground: "bg-gradient-primary",
        },
        5: {
          title: "Technical Package Preparation",
          init: "T",
          iconBackground: "bg-gradient-primary",
        },
      },
      ssdata: {
        0: {
          title: "Inscription Of Stability Sample",
          init: "P",
          iconBackground: "bg-gradient-primary",
        },
        1: {
          title: "Online Data Analytics & Stability Sample",
          init: "O",
          iconBackground: "bg-gradient-primary",
        },
      },
      itdata: {
        0: {
          title: "Product Label With Qr Code",
          init: "P",
          iconBackground: "bg-gradient-primary",
        },
      },
    };
  },
};
</script>

<style scoped></style>
