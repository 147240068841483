<template>
  <div>
    <h1
      class="p-4"
      :style="[getRoute() === 'licence' ? { color: 'white' } : null]"
      style="text-align: left"
    >
      Licence
    </h1>
    <div
      class="row p-3 col-lg-12 center"
      style="flex-wrap: wrap; justify-content: space-evenly"
    >
      <div
        v-for="(data, index) in data"
        :key="index"
        class="card col-lg-3 col-md-4 col-12 d-flex mt-2 mb-4 center shadow-none align-items-stretch"
        style="
          margin-right: 4%;
          margin-left: 0%;
          justify-content: space-evenly;
          item-align: center;
          align: center;
        "
      >
        <div
          class="p-3 col-lg-12 center"
          style="flex-wrap: wrap; justify-content: space-evenly"
        >
          <img
            v-if="
              data.title ===
              'NABL ACCREDITED (ISO/IEC 17025:2017, CER. NO. TC-11941)'
            "
            src="../../../assets/img/nabl-logo.png"
            class="card-img-top img-fluid w-50 h-60"
            alt="../../../assets/img/nabl-logo.png"
          />

          <img
            v-if="
              data.title === 'FDCA, GUJARAT APPROVED (LIC.NO. GTL/37/68)    '
            "
            src="../../../assets/img/fdca.png"
            class="card-img-top img-fluid  w-50 h-60"
            alt="../../../assets/img/fdca.png"
          />

          <img
            v-if="data.title === 'ISO 9001:2015 CERTIFIED'"
            src="../../../assets/img/iso.png"
            class="card-img-top img-fluid w-50 h-60"
            alt="../../../assets/img/iso.png"
          />
        </div>
        <div class="card-body">
          <h6 class="card-text" style="text-align: left">
            {{ data.title }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LicencePage",
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
  data() {
    return {
      data: {
        0: {
          title: "NABL ACCREDITED (ISO/IEC 17025:2017, CER. NO. TC-11941)",
          init: "N",
          iconBackground: "bg-gradient-primary",
        },
        1: {
          title: "FDCA, GUJARAT APPROVED (LIC.NO. GTL/37/68)    ",
          init: "F",
          iconBackground: "bg-gradient-primary",
        },
        2: {
          title: "ISO 9001:2015 CERTIFIED",
          init: "I",
          iconBackground: "bg-gradient-primary",
        },
      },
    };
  },
};
</script>

<style scoped></style>
